import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import Img from 'gatsby-image';
import Layout from './layout';

export const query = graphql`
	query PostsByID($id: String!) {
		mdx(id: { eq: $id }) {
			body
			timeToRead
			frontmatter {
				title
				date(formatString: "Do MMM YYYY")
				featuredImage {
					childImageSharp {
						fluid(traceSVG: { color: "#464646" }) {
							...GatsbyImageSharpFluid_tracedSVG
						}
					}
				}
			}
		}
	}
`;

const CalendarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    className="h-6 w-6 inline mr-2 mb-1 align-middle"
		>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
    />
  </svg>
);

const ClockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    className="h-6 w-6 inline mr-1.5 mb-1 align-middle"
		>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

const postTemplate = ({ data }) => {
  const { frontmatter, body, timeToRead } = data.mdx;
  return (
    <Layout className="bg-white pb-20">
      <div className="dark:text-gray-100 text-gray-800 p-6  lg:py-20 text-2xl leading-relaxed">
        <div className="max-w-5xl mx-auto">
          <h1 className="text-3xl lg:text-6xl mb-12 text-center capitalize font-semibold ">
            {frontmatter.title}
          </h1>
        </div>
        <div className=" max-w-3xl mx-auto ">
          {frontmatter.featuredImage && (
          <Img
            fluid={
								frontmatter.featuredImage.childImageSharp.fluid
							}
            className="mb-8 rounded"
          />
          )}
          <p className="mb-10 text-gray-400 text-base">
            <CalendarIcon />
            {frontmatter.date}
            {' '}
            <ClockIcon />
            {timeToRead}
            {' '}
            min read
          </p>
          <MDXRenderer>{body}</MDXRenderer>
        </div>
      </div>
    </Layout>
  );
};
export default postTemplate;
